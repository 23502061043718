#resume {
  border-top: var(--TNY) dashed var(--BD);
  padding: calc(2 * var(--SML)) var(--MED);
  border-bottom: var(--TNY) dashed var(--BD);
}

#resume-header {
  margin: 0ex var(--MED);
  display: flex;
  align-items: center;
  margin-bottom: var(--SML);
  justify-content: space-between;
}

#resume-section-title {
  color: var(--LN);
  font-family: var(--FONT_EN_HEADING);
}

#resume hr {
  border-color: var(--DM);
}

#resume-button-space {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#resume-button-space button {
  color: var(--LN);
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  text-decoration: none;
}

@media (hover: hover) { /* solves sticky problem on touch devices */
  #resume-button-space button:hover {
    color: var(--HV);
    border-bottom: var(--TNY) solid var(--LN);
  }
}

.entry {
  display: flex;
  align-items: center;
  margin: var(--SML) var(--MED);
  justify-content: space-between;
}

.right-align {
  text-align: right;
}

.resume-list {
  font-size: calc(0.8 * var(--MED));
  margin: 0ex calc(2 * var(--BIG));
}
