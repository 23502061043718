@font-face {
  font-family: 'Dunhill';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/lmromandunh.woff2') format('woff2');
}

@font-face {
	font-family: 'Noori';
  font-display: swap;
  src: url('./fonts/noori_regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Noori';
  font-display: swap;
  font-style: italic;
  src: url('./fonts/noori_kasheeda.woff2') format('woff2');
}

@font-face {
	font-family: 'Scheherazade';
  font-display: swap;
  src: url('./fonts/scheherazade.woff2') format('woff2');
}