#page:lang(ur) {
  flex-direction: row-reverse;
}

#aside:lang(ur) {
  border-left: none;
  border-right: var(--TNY) dashed var(--BD);
}

#page #main:lang(ur) {
  word-spacing: 0em; /* Does not do anything, just a reminder. */
  font: var(--FONT_UR_SIZE) var(--FONT_UR);
}

#page #main h1:lang(ur) {
  font-size: var(--FONT_UR_SIZE);
}

#page #main h2:lang(ur) {
  font-size: calc(0.84*var(--FONT_UR_SIZE));
}

#page #main h1:lang(ur),
#page #main h2:lang(ur),
#page #main h3:lang(ur),
#page #main h4:lang(ur),
#page #main h5:lang(ur),
#page #main h6:lang(ur) {
  margin-top: 0em;
  margin-bottom: calc(-1*var(--TNY));
  font-weight: normal;
  font-family: var(--FONT_UR_HEADING);
}

#page #main blockquote:lang(ur) {
  border-left: none;
  border-right: var(--TNY) solid var(--BD);
}

#page #main pre:lang(ur),
#page #main code:lang(ur),
#page #main kbd:lang(ur),
#page #main samp:lang(ur),
#page #main tt:lang(ur) {
  font: inherit; /* inherits urdu's defualt noori fonts */
}

#page #main pre:lang(ur) {
  text-align: center;
  word-spacing: var(--MED);
}

#page #main ul ul:lang(ur),
#page #main ul ol:lang(ur),
#page #main ol ul:lang(ur),
#page #main ol ol:lang(ur) {
  margin-left: var(--MED);
  margin-right: var(--MED);
}

/* Bit hacky but no other fix.*/
#page .task-list-item input[type=checkbox]:lang(ur) {
  margin: 0em -1.6em 0.25em 1.2em;
}
