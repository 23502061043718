#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--MED) 0;
}

#left {
  padding-bottom: var(--SML);
  display: flex;
  align-items: center;
}

#text {
  color: var(--LN);
  font: var(--FONT_TITLE);
}

#logo {
  padding-top: var(--SML);
  font: var(--FONT_LOGO);
}

.convexhull { /* Make the boxes class elements tight and inline. */
  height: var(--MED);
  display: inline-flex;
  align-items: center;
}
