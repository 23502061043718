@charset "UTF-8";
@import 'fonts.css';
@import 'urdu.css';
@import url('https://tashfeen.org/raw/utils/css/vars.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid green; */
}

body {
  color: var(--FG);
  font: var(--FONT_EN);
  background-color: var(--BG);
  max-width: var(--PAGE_WIDTH);
  line-height: var(--LINE_HEIGHT); /* https://tinyurl.com/y3omcgju */
  margin: 0 auto;  /* top and bottom margins are 0 and left right auto */
}

a {
  color: var(--LN);
  text-decoration: none;
}

@media (hover: hover) { /* solves sticky problem on touch devices */
  a:hover {
    color: var(--HV);
    border-bottom: var(--TNY) solid var(--LN);
  }
}
