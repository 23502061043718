/* Due to very particular requirements of mobiles,
 * it is okay to use numbers here.
 */
@media only screen and (max-width: 750px) {
  body #page,
  body #page:lang(ur) {
    border-top-width: 0.15em;
    border-bottom-width: 0.15em;
    flex-direction: column;
  }
  
  body #header {
    margin-top: 0ex;
    font-size: 5vw;
    flex-direction: column;
  }

  body #header #left {
    margin-right: 9.9em;
  }

  body #header #logo {
    padding: 3ex 0.1em 0ex 1.35em;
  }

  body #header #text {
    padding-top: 1ex;
  }
  
  body #header #nav {
    padding-left: 1em;
  }
  
  body #page #main {
    font-size: 1.1em;
  }

  body #page #main h1 {
    font-size: 1.4em;
  }
  
  body #page #aside {
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: 0.15em dashed var(--BD);
  }
  
  body #page #main th,
  body #page #main td {
    padding: 0.2ex 0.3em;
  }

  /* urdu on mobiles */
  #page #main blockquote:lang(ur),
  #page #main pre:lang(ur) {
    margin-left: 0.6em;
    margin-right: 0.6em;
  }

  #page #main ul:lang(ur),
  #page #main ol:lang(ur) {
  /* list-style-position is outside therefore we need
   * more than 0.25em in margins.
   */
    margin-left: 1em;
    margin-right: 1em;
  }

  #page #main pre:lang(ur) {
    word-spacing: 0em;
  }

  body #page #main th:lang(ur),
  body #page #main td:lang(ur) {
    padding-left: 0.17em;
    padding-right: 0.17em;
  }
}
