#page {
  display: flex;
  border-top: var(--TNY) dashed var(--BD);
  border-bottom: var(--TNY) dashed var(--BD);
}

#page #main {
  width: 100%;
  padding: calc(2 * var(--SML)) var(--MED);
}

#page #main hr {
  width: 97%;
  margin: var(--TNY) auto;
  border-color: var(--DM);
}

#page #main h1:first-child {
  margin-top: 0em;
}

#page #main h1 {
  text-align: center;
  font-weight: normal;
  font-variant: small-caps;
  font-family: var(--FONT_EN_HEADING);
}

#page #main p,
#page #main h1,
#page #main h2,
#page #main h3,
#page #main h4,
#page #main h5,
#page #main h6 {
  margin: revert;
}

#page #main table {
  text-align: center;
  margin: var(--MED) auto;
  border-collapse: collapse;
}

#page #main th,
#page #main td {
  border: var(--TNY) solid var(--DM);
  padding: var(--TNY) var(--MED);
}

#page #main img {
  display: block;
  margin: var(--MED) auto;
}

#page #main pre,
#page #main code,
#page #main kbd,
#page #main samp,
#page #main tt {
  font-family: var(--FONT_EN_FX);
}

#page #main pre,
#page #main code {
  background: var(--DM);
}

#page #main pre code { /* avoid double highlight */
  background: none;
}

#page #main pre {
  /* This is not the same length as blockquote because
   * of the different fonts. Pre always has monospaced
   * and block quote may not.
   */
  margin: var(--MED) var(--BIG);
  padding: calc(2 * var(--SML));
}

#page #main blockquote {
  background: var(--DM);
  border-left: var(--TNY) solid var(--BD);
  margin: var(--MED) var(--BIG);
  padding: calc(2 * var(--SML));
}

#page #main blockquote p {
  margin: 0;
}

#page #main ul,
#page #main ol {
  margin: var(--MED) var(--BIG);
}

#page #main ul ul,
#page #main ul ol,
#page #main ol ul,
#page #main ol ol {
  margin-top: 0em;
  margin-bottom: 0em;
}

#page ol {
  list-style-type: decimal;
}

#page ol > li > ol {
  list-style-type: lower-roman;
}

#page ol > li > ol > li > ol {
  list-style-type: lower-alpha;
}

#page .contains-task-list {
  list-style-type: none;
}

/* Bit hacky but no other fix.*/
#page .task-list-item input[type=checkbox] {
  margin: 0em 0.2em 0.25em -1.1em;
  vertical-align: middle; /* Stollen from github using inspect. */
}
