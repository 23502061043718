#nav {
  margin-right: calc(-1*var(--BIG));
}

#nav a {
  font: var(--FONT_EN);
  margin-right: var(--BIG); /*must be -1*#nav.margin-right*/
}

.active{ /* should always be the same as "a:hover" in frame.css */
    color: var(--HV);
    border-bottom: var(--TNY) solid var(--LN);
}
