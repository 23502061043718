#aside {
  width: 20%;
  /* Needs to be padding instead of margins so the boder connects. */
  padding: calc(2 * var(--SML)) var(--MED);
  border-left: var(--TNY) dashed var(--BD);
}

#aside ul,
#aside ol {
  margin-left: var(--MED);
}
